import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/common/layout"
import SEO from "../../components/common/seo"

import withLocation from "../../components/common/withlocation"

import { getParamsFromUrl } from "../../utils/urls"
import { trackEvent } from "../../utils/tracking"


const PartnerPaidPage = ({data, path, location}) => {

  const params = getParamsFromUrl(location);
  const type = params['utm_content']; // stripe will redirect with utm_content, but it could be blank (handle below)

  useEffect(() => {
    trackLoad()
  }, [])

  const trackLoad = () => {
    // tracks that we got here, with all the query params
    trackEvent("Paid", params)
  }

  return (
    <Layout pageTitle="Thank you!" enableShare={false} >
      <SEO title="Promotion Paid | The Hive Index" description="" dontIndex={true} />

      {type === 'community_promotion' ? (
        <div>
          <p className="mb-2">Thank you for promoting your community on the Hive Index!</p>
          <p className="mb-2">We will be in touch via email to notify you when your promoted listing is live.</p>
          <p className="mb-2">If you are promoting a community that is not live yet, make sure you've filled out the <Link to="/submit/add/" className="text-rose-500 underline">submission form</Link> so that we have your community details.</p>
        </div>
      ) : type === 'sponsorship_promotion' ? (
        <div>
          <p className="mb-2">Thank you for sponsoring the Hive Index site!</p>
          <p className="mb-2">We will be in touch via email shortly to kick off the sponsorship.</p>
        </div>
      ) : type === 'gold_membership' ? (
        <div>
          <p className="mb-2">Thank you for buying Gold!</p>
          <p className="mb-2">We will be in touch via email shortly with your boosted listing.</p>
        </div>
      ) : (
        <div>
          <p className="mb-2">Thank you for your purchase!</p>
          <p className="mb-2">We will be in touch via email to confirm your details.</p>
        </div>
      )}

      <p className="mt-10">
        If you do not hear from us within 48 hours, please send an email to <a href="mailto:contact@thehiveindex.com" className="text-rose-500 underline">
        contact@thehiveindex.com</a>
      </p>

    </Layout>
  )
}


export default withLocation(PartnerPaidPage)
